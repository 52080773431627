import { PublicConfig } from '../../models/environment.public';
import { commonEnvVars, stagingEnv } from 'src/common/environments/environment.public.common';

const { features, ...rest } = stagingEnv;

const config: PublicConfig = {
    appId: 'l7xx5e5d882c07aa4188ba03f91ffd0dbe2e',
    brand: 'Bryant',
    brandPath: 'bryant',
    publicBaseUrl: 'https://staging-bryant.hvacpartners.com',
    siteCode: 622,
    features: {
        ...features,
        covid19Information: true,
        newsLetterSignUp: false,
        auth: {
            login: true,
            externalLogin: false
        }
    },
    ...rest
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const environment: any = { ...commonEnvVars(config) };
